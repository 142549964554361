import { isEqual } from 'lodash-es';
import {
    EMPTY,
    Observable,
    combineLatest,
    from,
    merge,
    of,
    pairwise,
    skip,
    switchMap
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    take,
    tap,
    timeout,
    withLatestFrom
} from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import { sfEvent } from '@mhp/aml-ui-shared-services/salesforce-tracking';
import {
    CustomError,
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import { AnimationState, EnvironmentState } from '@mhp/communication-models';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    ENGINE_CONTROL_SOCKET_STRATEGY_CONFIG_TOKEN,
    EngineControlService,
    EngineControlSocketStrategyConfig,
    ErrorHandlerService,
    gtmGA4SetDataLayerProps,
    gtmGA4Track,
    selectAnimationStates,
    selectEnvironmentState
} from '@mhp/ui-shared-services';
import { EngineStateService } from '@mhp/ui-shared-services/engine/engine-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../environments/environment';
import { AmlBreakpoints } from '../../common/breakpoints/AmlBreakpoints';
import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import { VideoPlaybackComponent } from '../../common/video-playback/video-playback.component';
import { LabelHelperService } from '../../i18n/label-helper.service';
import { SALESFORCE_EVENT_START_MODEL_CONFIGURATION } from '../../salesforce-events/salesforce-events';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { selectEngineUiState } from '../../state/selectors/aml-ui-shared-state.selectors';
import { CarFeatureControlService } from '../car-features/car-feature-control.service';
import { ConfigurationSessionInfo } from '../common/configuration-interfaces';
import { ExtendedUiOptionGroup } from '../configuration-model/configuration-interfaces';
import { EnvironmentInfoService } from '../environment-info/environment-info.service';
import { ModelOutdatedDialogComponent } from '../model-outdated/model-outdated-dialog/model-outdated-dialog.component';
import { ConfigurationNodeLookupService } from '../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { selectActiveConfigurationArea } from '../state';
import { VisualizationMode } from '../state/local-configuration-state.interface';
import { VisualizationModeService } from '../visualization-mode/visualization-mode.service';

@UntilDestroy()
@Injectable()
export class ConfigurationMainSupportService implements OnDestroy {
    private trackingEnabled = false;

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly engineControlService: EngineControlService,
        private readonly engineStateService: EngineStateService,
        private readonly visualizationModeService: VisualizationModeService,
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly carFeatureControlService: CarFeatureControlService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly dialogService: UiMatDialogService,
        private readonly environmentInfoService: EnvironmentInfoService,
        @Inject(ENGINE_CONTROL_SOCKET_STRATEGY_CONFIG_TOKEN)
        private readonly engineControlConfig: EngineControlSocketStrategyConfig,
        private readonly labelHelperService: LabelHelperService
    ) {
        this.handleVideoDeeplink();
        this.handleModelOutdated();
    }

    @MemoizeObservable()
    getConfigurationBarDisplayMode$(): Observable<'COMPACT' | 'LARGE'> {
        return combineLatest([
            this.breakpointObserver.observe([AmlBreakpoints.Landscape]),
            this.breakpointObserver.observe([
                AmlBreakpoints.HandsetLandscapeStageFullscreen
            ])
        ]).pipe(
            debounceTime(0),
            map(
                ([isLandscape, handsetLandscapeStageFullscreen]):
                    | 'COMPACT'
                    | 'LARGE' => {
                    if (handsetLandscapeStageFullscreen.matches) {
                        return 'COMPACT';
                    }
                    return isLandscape.matches ? 'LARGE' : 'COMPACT';
                }
            ),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getActiveTopLevelCategory$() {
        return this.applicationStateService
            .getState()
            .pipe(selectEngineUiState)
            .pipe(map((uiState) => uiState?.activeTopLevelCategory));
    }

    @MemoizeObservable()
    showFooter$(): Observable<boolean> {
        return combineLatest([
            this.getActiveTopLevelCategory$(),
            this.getConfigurationBarDisplayMode$()
        ]).pipe(
            map(
                ([categoryId, displayMode]) =>
                    (displayMode === 'COMPACT' &&
                        categoryId !==
                            environment.appConfig.configuration
                                .identifierSummary) ||
                    (displayMode === 'LARGE' &&
                        categoryId !==
                            environment.appConfig.configuration
                                .identifierSummary &&
                        categoryId !==
                            environment.appConfig.configuration
                                .identifierPersonalisation &&
                        categoryId !==
                            environment.appConfig.configuration
                                .identifierAccessories)
            )
        );
    }

    /**
     * Opens the environment info-layer.
     */
    showEnvironmentInfo(environmentId: string): Observable<void> {
        return this.environmentInfoService.showInfoLayerForEnvironment$(
            environmentId
        );
    }

    /**
     * Returns true in case the environment has extra-info available.
     * @param environmentId The environments id.
     */
    hasEnvironmentInfo(environmentId: string) {
        return this.environmentInfoService
            .getEnvironmentsWithInfo()
            .includes(environmentId);
    }

    enableTracking() {
        if (this.trackingEnabled) {
            return;
        }
        this.trackingEnabled = true;

        // emit configuration session start
        this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                filter(
                    (
                        configurationSessionInfo
                    ): configurationSessionInfo is ConfigurationSessionInfo =>
                        !!configurationSessionInfo
                ),
                take(1),
                untilDestroyed(this)
            )
            .subscribe((configurationSessionInfo) => {
                const { productId, modelId } = configurationSessionInfo;
                const derivativeName = this.labelHelperService.getDerivateName(
                    modelId,
                    productId,
                    'en'
                );

                gtmGA4Track('enter_configuration_session', {
                    model_category: modelId,
                    model_id: productId,
                    model_name: derivativeName
                });

                // SF Data Cloud: Start Model Configuration
                sfEvent({
                    interaction: {
                        ...SALESFORCE_EVENT_START_MODEL_CONFIGURATION,
                        modelCode: modelId,
                        variantCode: productId
                    }
                });
            });

        // keep model-information updated
        this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(untilDestroyed(this))
            .subscribe((configurationSessionInfo) => {
                if (!configurationSessionInfo) {
                    return;
                }
                gtmGA4SetDataLayerProps({
                    model_category: configurationSessionInfo.modelId,
                    model_id: configurationSessionInfo.productId,
                    model_name: this.labelHelperService.getDerivateName(
                        configurationSessionInfo.modelId,
                        configurationSessionInfo.productId,
                        'en'
                    )
                });
            });

        // camera-events
        this.engineControlService
            .getActiveCamera$()
            .pipe(
                filter((camera): camera is string => !!camera),
                skip(1),
                untilDestroyed(this)
            )
            .subscribe((camera) => {
                // emit camera-change event
                gtmGA4Track('vehicle_angles_click', {
                    vehicle_angle: camera
                });
                gtmGA4Track('vehicle_angles_click_sequence');
            });

        // track renderEnvironment event when environment changes
        this.applicationStateService
            .getState()
            .pipe(selectEnvironmentState)
            .pipe(
                distinctUntilChanged(),
                filter(
                    (environmentState): environmentState is EnvironmentState =>
                        !!environmentState
                ),
                untilDestroyed(this)
            )
            .subscribe((environmentState) => {
                gtmGA4SetDataLayerProps({
                    active_environment: environmentState.id,
                    day_night_mode:
                        environmentState.state ===
                        EnvironmentLightingProfileState.DAY
                            ? 'day'
                            : 'night'
                });
            });

        // track active animations
        this.applicationStateService
            .getState()
            .pipe(selectAnimationStates)
            .pipe(
                distinctUntilChanged(),
                filter(
                    (animationState): animationState is AnimationState[] =>
                        !!animationState
                ),
                untilDestroyed(this)
            )
            .subscribe((animationState) => {
                gtmGA4SetDataLayerProps({
                    active_animation: animationState
                        .filter((animation) => animation.state === 'END')
                        .map((animation) => animation.id)
                        .join(' | ')
                });
            });

        // track toggle navigation-areas (environment-, camera-, animation-selection)
        this.applicationStateService
            .getLocalState()
            .pipe(
                selectActiveConfigurationArea,
                map((activeConfigurationArea) => {
                    if (!activeConfigurationArea) {
                        return undefined;
                    }
                    return {
                        environments: 'environment_selection',
                        animations: 'animation_selection',
                        cameras: 'camera_selection'
                    }[activeConfigurationArea];
                }),
                pairwise(),
                untilDestroyed(this)
            )
            .subscribe(
                ([previousEventActionPostfix, currentEventActionPostfix]) => {
                    const openObj = {};
                    const closeObj = {};

                    openObj[`${currentEventActionPostfix}_status`] = 'open';
                    closeObj[`${previousEventActionPostfix}_status`] = 'close';

                    if (previousEventActionPostfix) {
                        gtmGA4Track(
                            `${previousEventActionPostfix}_click`,
                            closeObj
                        );
                    }

                    if (currentEventActionPostfix) {
                        gtmGA4Track(
                            `${currentEventActionPostfix}_click`,
                            openObj
                        );
                    }
                }
            );

        // toplevel and sublevel category
        const activeCategoryPath$ = this.applicationStateService
            .getState()
            .pipe(selectEngineUiState)
            .pipe(map((uiState) => uiState?.activeCategoryPath));

        const firstLevelCategory$: Observable<string | undefined> =
            activeCategoryPath$.pipe(
                map((categoryPath) => categoryPath?.[0]),
                distinctUntilChanged(isEqual)
            );
        const secondLevelCategory$: Observable<string | undefined> =
            activeCategoryPath$.pipe(
                map((categoryPath) => categoryPath?.[1]),
                distinctUntilChanged(isEqual)
            );
        const thirdLevelCategory$: Observable<string | undefined> =
            activeCategoryPath$.pipe(
                map((categoryPath) => categoryPath?.[2]),
                distinctUntilChanged(isEqual)
            );

        [
            {
                action: 'page_menu',
                observable: firstLevelCategory$
            },
            {
                action: 'page_submenu',
                observable: secondLevelCategory$
            },
            {
                action: 'variant_category',
                observable: thirdLevelCategory$
            }
        ].forEach((toBeTrackedItem) => {
            toBeTrackedItem.observable
                .pipe(
                    withLatestFrom(
                        this.productConfigurationSessionService.getOptionGroups$()
                    ),
                    map(([activeCategory, optionGroups]) => {
                        if (!optionGroups) {
                            return;
                        }

                        if (!activeCategory) {
                            // clean the according property if it's not the root page_menu property
                            if (toBeTrackedItem.action !== 'page_menu') {
                                gtmGA4SetDataLayerProps({
                                    [toBeTrackedItem.action]: undefined
                                });
                            }
                        } else {
                            const foundNode =
                                this.nodeLookupService.findNodeById(
                                    activeCategory,
                                    optionGroups
                                );
                            if (!foundNode) {
                                return;
                            }
                            gtmGA4Track(`${toBeTrackedItem.action}_click`, {
                                [toBeTrackedItem.action]: foundNode.node.name
                            });
                        }
                    }),
                    untilDestroyed(this)
                )
                .subscribe();
        });
    }

    /**
     * Initialize the behavior that when a user moves through the categories and
     * a group has the meta-flag TBD, the roof is automatically closed and opened
     * again when leaving the category (unless the user started actively toggling the
     * roof).
     *
     * See https://mhpimmersive.atlassian.net/browse/AMR-465
     *
     * @return Callback to cancel the behavior.
     */
    initHandleRoofBehaviorDependentOnActiveCategory(): () => void {
        const subscription = this.applicationStateService
            .getState()
            .pipe(
                selectEngineUiState,
                map((uiState) => uiState?.activeCategoryPath),
                withLatestFrom(
                    this.applicationStateService
                        .getLocalState()
                        .pipe(
                            map((state) => state.configuration.userToggledRoof)
                        )
                ),
                filter(([, userToggledRoof]) => !userToggledRoof),
                map(([categoryPath]) => categoryPath),
                distinctUntilChangedEquality(),
                switchMap(
                    (
                        categoryPath
                    ): Observable<'OPEN' | 'CLOSED' | undefined> => {
                        if (!categoryPath) {
                            return of(undefined);
                        }
                        return this.productConfigurationSessionService
                            .getOptionGroups$()
                            .pipe(
                                take(1),
                                filter(
                                    (
                                        optionGroups
                                    ): optionGroups is ExtendedUiOptionGroup[] =>
                                        !!optionGroups
                                ),
                                map((optionGroups) =>
                                    this.nodeLookupService.collectNodes(
                                        (node) =>
                                            categoryPath?.indexOf(node.id) > -1,
                                        optionGroups
                                    )
                                ),
                                map((collectedGroups) =>
                                    collectedGroups.find(
                                        (group) =>
                                            group.meta?.adviseRoofClosed ===
                                            true
                                    )
                                        ? 'CLOSED'
                                        : 'OPEN'
                                )
                            );
                    }
                ),
                filter(
                    (targetState): targetState is 'OPEN' | 'CLOSED' =>
                        !!targetState
                ),
                switchMap((targetRoofState) =>
                    this.carFeatureControlService
                        .setRoofState$(targetRoofState)
                        .pipe(
                            this.errorHandlerService.applyRetry(),
                            catchError(() => EMPTY)
                        )
                ),
                untilDestroyed(this)
            )
            .subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }

    ngOnDestroy() {
        this.resetStreamIfRequired();
    }

    private resetStreamIfRequired() {
        if (environment.appConfig.stream.resetTo2dOnConfigurationLeave) {
            this.visualizationModeService.setTargetVisualizationMode(
                VisualizationMode.BASIC
            );
        }
    }

    /**
     * Handles the case where the user might enter the application using a video-deeplink.
     * Here, it is checked, whether there is a videoId query-param available.
     * If yes, we take the parameters content, remove it from the URL and show the video.
     * @private
     */
    private handleVideoDeeplink() {
        const videoHash = this.activatedRoute.snapshot.queryParams.videoId;

        if (!videoHash) {
            return;
        }

        // remove query-param from route
        from(
            this.router.navigate([], {
                queryParams: {
                    videoId: null
                },
                queryParamsHandling: 'merge',
                relativeTo: this.activatedRoute,
                replaceUrl: true
            })
        )
            .pipe(
                this.errorHandlerService.applyRetry(),
                catchError(() => EMPTY)
            )
            .subscribe();

        // show video to user
        this.configurationSessionInfoService
            .getActiveProductId$()
            .pipe(
                filter(
                    (activeProductId): activeProductId is string =>
                        !!activeProductId
                ),
                timeout(10000),
                take(1),
                map(
                    (activeProductId) =>
                        `${this.engineControlConfig.cinematicDownloadOrigin}/video/${activeProductId}/${videoHash}.mp4`
                ),
                switchMap((videoUrl) => {
                    // track using GA
                    gtmGA4Track('deeplink_video_start');

                    return this.dialogService
                        .openFullscreen$(VideoPlaybackComponent)
                        .pipe(
                            switchMap((dialogRef) => {
                                const componentRef =
                                    dialogRef.componentInstance;
                                componentRef.videoUrl = videoUrl;
                                return merge(
                                    dialogRef
                                        .afterClosed()
                                        .pipe(
                                            tap(() =>
                                                gtmGA4Track(
                                                    'deeplink_video_stop'
                                                )
                                            )
                                        ),
                                    componentRef.videoError.pipe(
                                        tap(() =>
                                            gtmGA4Track('deeplink_video_failed')
                                        ),
                                        tap((errorEvent) =>
                                            this.errorHandlerService.showErrorMessage(
                                                () =>
                                                    'Failed loading video. Please retry later.',
                                                new CustomError(
                                                    'Failed loading video',
                                                    errorEvent
                                                )
                                            )
                                        )
                                    )
                                );
                            }),
                            take(1)
                        );
                }),
                this.errorHandlerService.applyRetry()
            )
            .subscribe();
    }

    private handleModelOutdated() {
        if (environment.appConfig.dealer.dealerBuild) {
            return;
        }

        this.configurationSessionInfoService
            .getActiveProductId$()
            .pipe(
                filter((productId): productId is string =>
                    environment.appConfig.configuration.outdatedDerivatives.includes(
                        productId
                    )
                ),
                switchMap(() =>
                    this.dialogService.open$(ModelOutdatedDialogComponent, {
                        panelClass: 'mhp-ui-modal-panel--stretch-buttons',
                        backdropClass: BACKDROP_CLASS_BLURRY
                    })
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
